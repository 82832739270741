import React, {useState} from "react";
import Footer from "./Footer";
import ExampleNavbar from "./Navbar";
import ExampleJumbotron from "./FluidJumbotron";
import {Table, ButtonGroup, InputGroup, InputGroupAddon, Container, Row, Col,Button, Input} from 'reactstrap';
import SearchIcon from '@material-ui/icons/Search';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import WbCloudyIcon from '@material-ui/icons/WbCloudy';
import {db} from '../config';
import CreateHardinessArea from "./CreateHardinessArea";
import Note from "./Note";
// import { createTrue } from "typescript";

class GetStarted extends React.Component{

  constructor (props) {
    super(props);
    this.state = {
      zip:'',
      hardinessData:{},
      todos: {},
      show: false,
      loading: false,
    };
    //binding neccessary to make 'this' work on callback
    this.handleClick =this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.componentDidMount=this.componentDidMount.bind(this);
  }

  handleChange(event){
    this.setState({zip: event.target.value});
    // console.log(this.state.zip);
  }

  handleClick(event) {
    // console.log('Search by zip was submitted:'+this.state.zip);
    this.setState({zip: this.state.zip});
    this.componentDidMount();

    event.preventDefault();
    this.setState({
      show: true,
    });
  }

  componentDidMount() {
    this.setState({loading: true});
    db.ref('/phm_us_zipcode/'+this.state.zip ).on('value', querySnapShot => {
      let data = querySnapShot.val() ? querySnapShot.val() : {};
      // let hardinessData = {...data};
      // console.log(data);
      // console.log('USDA Plant Hardiness Zone: '+data.zone);
      // console.log('Temperature Range: '+data.trange+'°F');
      this.setState({
        hardinessData:  {...data},
      });
      // console.log("Show table?"+this.state.show);

    });

  }
  
  tempConverter(string) {
    var [minF, maxF] = string.split(' to ');
    return this.fahrenheitToCelsius(minF)+' to '+ this.fahrenheitToCelsius(maxF);
  }
  fahrenheitToCelsius(tempF) {
    return Math.round((parseInt(tempF)-32)*(5/9));
  }

  render(){
    
    return (
      <div >
        <ExampleNavbar />
        <ExampleJumbotron />
        {/* <p>The <strong>greener thumb</strong> will guide you through the process to create the garden of your dreams.</p> */}

        <Container className="themed-container" fluid={true}>
          <h1 className="display-4">Get Started</h1>
          <p className="lead">The health of your plants depends on their environment. Find plants that thrive in you area, based on setting, climate, and exposure to sunlight.</p>
          
          <h2>Location</h2>
          <p className="lead" >Where will your plants grow?</p>
          
          <Container fluid>

            <Row >

              <Col lg="6" xl="8">
                <img className="bw-img" src="https://planthardiness.ars.usda.gov/PHZMWeb/images_ui/homepage_map.jpg" alt="usda hardiness map" />
                {/* <UsdaDataReturned /> */}
              </Col>

              <Col sm="6" md="6" lg="3"  xl="4">
                <h4>Outdoors</h4>
                
                <p className="lead" >Find plants that thrive in your climate by determining your <a href="https://planthardiness.ars.usda.gov/PHZMWeb/">USDA Plant Hardiness Zone</a>.</p>
                {/* <Input onChange={this.handleChange} type="text" name="zip" id="zipCode" placeholder="ZIP code"></Input>
                <Button onClick={this.handleClick} color="secondary" size="lg" ><SearchIcon /></Button> */}
                
                <InputGroup>
                  <Input placeholder="ZIP code"onChange={this.handleChange} type="text" name="zip" id="zipCode"  />
                  <InputGroupAddon addonType="append"  onClick={this.handleClick} color="secondary" >
                    <Button><SearchIcon /></Button>
                  </InputGroupAddon>
                </InputGroup>
                { this.state.show && <Table bordered>
                  <thead>
                    <tr>
                      <th>ZIP Code</th>
                      <th>Hardiness Zone</th>
                      <th>Temperature Range (°F)</th>
                      <th>Temperature Range (°C)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{this.state.hardinessData.zipcode}</td>
                      <td>{this.state.hardinessData.zone}</td>
                      <td>{this.state.hardinessData.trange+' °F'}</td>
                      <td>{this.tempConverter(this.state.hardinessData.trange)+' °C'}</td>
                    </tr>
                  </tbody>
                </Table>

                }
              </Col>


              <Col sm="6" md="6" lg="3"  xl="4">
                <h4>Indoors</h4>
                <p className="lead" >Tropical plants tend do well indoors, due to minimal temperature fluctuations.</p>
                {/* <p>To avoid pests, consider potting mix for indoor plants.</p> */}
                <Button variant="secondary" href= "https://www.goodhousekeeping.com/home/gardening/advice/g1285/hard-to-kill-plants/">Browse</Button>
  
              </Col>
              </Row>

            {/* </Row> */}
            <hr />
            <Row>
              <Col>
              <h2>Sunlight</h2>
              <p className="lead">How much sun exposure the plants will recieve?</p>
              <ButtonGroup aria-label="Basic example">
                <Button variant="secondary"><WbSunnyIcon /></Button>
                <Button variant="secondary"><WbCloudyIcon /></Button>
                <Button variant="secondary"><CloudQueueIcon /></Button>
              </ButtonGroup>
              {/* <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/"     title="Flaticon">www.flaticon.com</a></div><div>Icons made by <a href="https://www.flaticon.com/authors/vectors-market" title="Vectors Market">Vectors Market</a> from <a href="https://www.flaticon.com/"     title="Flaticon">www.flaticon.com</a></div><div>Icons made by <a href="https://www.flaticon.com/authors/monkik" title="monkik">monkik</a> from <a href="https://www.flaticon.com/"     title="Flaticon">www.flaticon.com</a></div><div>Icons made by <a href="https://www.flaticon.com/authors/iconixar" title="iconixar">iconixar</a> from <a href="https://www.flaticon.com/"     title="Flaticon">www.flaticon.com</a></div> */}
              {/* <ToggleButtonGroupControlled /> */}
              {/* <SearchUSDA zip={zip} /> */}
              </Col>

            </Row>
          </Container>
          

        </Container>

        <Footer />
      </div>
    );
  };
};
export default GetStarted;


function UsdaDataReturned(){
  const [notes, setNotes] = useState([]);

  function addNote(newNote) {
    setNotes(prevNotes => {
      return [...prevNotes, newNote];
    });
  }

  function deleteNote(id) {
    setNotes(prevNotes => {
      return prevNotes.filter((noteItem, index) => {
        return index !== id;
      });
    });
  }

  return(
    <div>
    <Container fluid>
  
         <CreateHardinessArea onAdd={addNote} />

        {notes.map((noteItem, index) => {
          return (
              <Note
                key={index}
                id={index}
                title={noteItem.title}
                content={noteItem.content}
                onDelete={deleteNote}
              />
          );
        })}
      </Container>
      </div>
  );
}
export { UsdaDataReturned};