import React from "react";
import Footer from "./Footer";
import ExampleNavbar from "./Navbar";
import {Jumbotron, Container, Row, Col,Button } from 'reactstrap';
import FilterVintageIcon from '@material-ui/icons/FilterVintage';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const imgUrl = "https://images.unsplash.com/photo-1518531933037-91b2f5f229cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1800&q=80"
const backgroundImgStyle = {
  backgroundImage: 'url('+imgUrl+')',
  color: 'white',
}

class About extends React.Component {
  render() {

    return (
      <div >
        <ExampleNavbar />
        <Jumbotron fluid style={backgroundImgStyle}>
          <Container fluid >
            <p className="lead"> Welcome to the </p>
            <h1 className="display-3" ><i>Greener Thumb</i>.</h1>
            <p className="lead">Learn to cultivate your garden retreat.</p>
          </Container>
        </Jumbotron>

        <Container  >
          <h2  className="display-4">Garden Design</h2>

          <h3>A Note from the Author</h3>
          <p className="lead">Plants can transform any living space into a beautiful sanctuary. My goal in creating this site is to help you dream, design, and manifest your own a garden oasis wherever you live - whether that be a home, apartment, balcony, deck, yard, or windowsill. All you need is water, sunlight, soil, containers, and the plants themselves.</p>

          <h3>Backgound</h3>
          <p className="lead"><strong>What comes to mind when you think of gardening?</strong> From a very early age, I enjoyed planting and tending to flowers with my mother. The vast majority of wisdom shared within this site was learned by helping my mother garden as a child. I hope this information helps aspiring gardeners more fully enjoy the beauty of nature in our homes.</p>
          {/* <p className="lead">Although my mother readily admits she never had a green thumb, her landscaping choices were wise. She intentionally bought hearty, native plants based on rainfall and sunlight exposure of throughout the yard. We planted colorful petunias to brighten up the front yard and back deck. Harsh, noon sun bore down on the annuals planted in the urns and flower bed of the front yard; yet, they thrived in full sun despite the summer heat. Sunlight and water conditions varied significantly in the backyard, so we consulted the local experts at our favorite nursery. </p>
          <p className="lead">My mother trusted the nursery owners and staff to help her find the best plants for our lighting and water requirements, as they had done for decades. For instance, Leyland cypress trees were planted along the perimeter of our backyard, creating a 100-ft tall evergreen fence. Hydrangeas were planted along the back of the house; they were bathed in morning sun and recieved ample rain due to run-off from the roof. <strong>Each plant was chosen based on the lighting and water constraints of its location and environment.</strong> I recommend you do the same, as it affords your plants the greatest opportunity to thrive with minimal maintenance. Let's get started planning and designing the optimal garden for you!</p>
           */}
          <h4>Gardening Tips</h4>
          <p><FilterVintageIcon />Find plants native to your area. Local plants tend to fair better than exotic species.</p>
          <p><FilterVintageIcon />Assess how much water and sunlight your garden will recieve. Consult the experts at your local nursery to find the best fit for your environment. </p>
          <p><FilterVintageIcon />Determine your USDA hardiness zone by zip code to find plants known to excell in your climate.</p>
          <p><FilterVintageIcon />Include your favorite plants, flowers, trees, fruits, vegetables, and herbs that fit your climate zone.</p>
          <p><FilterVintageIcon />Remember, plant type determines soil type. Find the type of soil required by your plants. </p>
          <hr/>

          <h2 className="display-4">Re-Define Gardening</h2>
          <p className="lead">Gardening doesn't have to be labor-insensive. The principles below set the foundation for my understanding of <i>modern</i> gardening, which fits your needs, lifestyle, and constraints beautifully.</p>

          <Row>

            <Col>

              <h4>No Land, No Problem </h4>
              <p >A plot of land or yard is not require to create your garden retreat. A window sill can be a beatiful spot for flowers, herbs, and succulents. All you need is sunlight, soil, water, containers, and the plants themselves. </p>

              <img className="style-img" src="https://images.unsplash.com/photo-1496092607007-ca127e0b6a10?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1385&q=80" alt="window sill flowers" />
              <p className="caption">Photo by Nathan Fertig from Unsplash.</p>

              <h4>Plant Placement</h4>
              <p>Arrangment of plants and containers in a linear and symetrical fasion creates order and balance. Portabilty of container are ideal to move them around and find the arrangement that has the best fit in your space and style.</p>

            </Col>

            <Col>
              <h4>Limited Space? Plant Up <ArrowUpwardIcon /> </h4>
              <p>If space is limited, consider shelves and ladder-like plant stands that allow vertical planting. Vines with a trailing growth pattern are especially easy to grow on decks and balconies. Decorating shelves with plants via propgation is refreshing both indoors and outdoors.</p>

              <h4>Less is More </h4>
              <p>Showcasing a smaller number of favorite plants is significantly more appealing than a cluttered garden, especially if space is limited.
                Minimize the number of plants, colors, materials, and patterns to achive coherency and harmony. Plan arrangement of plants to optimize exposure to sunlight, while grouping similar plants, patterns, and colors.</p>
         
              <h4>Sharing is Caring</h4>
              <p>If find yourself with too many plants at home, bring plants to work to liven up your office space. Giving plants as gifts to friends and family is also quite rewarding, so ask around and offer plants to loved ones.</p>
   
            </Col>

            <Col>
             <h4>Location, Location, Location</h4>
          
              <p>My favorite gardens are located on decks and balconies overlooking a magnificent view. </p>
              {/* <p>Simple comforts like an outdoor run and dining set enhance the beauty of living amidst the flowers and trees. Find a view you love and create your garden using containers and window boxes.</p> */}

              <img className="style-img" src="https://images.unsplash.com/photo-1471623320832-752e8bbf8413?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60" alt="balcony flowers" />
              <p className="caption">Photo by JOHN TOWNER from Unsplash.</p>

              {/* <img className="style-img" src="https://images.unsplash.com/photo-1519276259990-216d5c67e2a8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80" alt="deck with a view" />
              <p className="caption">Photo by Matt Lamers from Unsplash.</p> */}
            </Col>

          </Row>
          <Row>
            <Col>
            <Button size="lg" block href="/get-started" path="/design" >Get Started</Button>
            </Col>
            <Col>
            <Button size="lg" block href="/Design" path="/design" >Browse Designs</Button>

            </Col>
          </Row>
          {/* <Button size="lg" block href="/get-started" path="/design" >Get Started</Button>
          <Button size="lg" block href="/Design" path="/design" >Browse Garden Design</Button> */}

          
        </Container>      

        <Footer />
      </div>
    );
  }

}

export default About;
