import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import App from "./components/App";
import Inspiration from "./components/Inspiration";
import About from "./components/About";
import Articles from "./components/Articles";
import GetStarted from "./components/GetStarted";
import Design from "./components/Design";
import 'bootstrap/dist/css/bootstrap.min.css';



const routing = (
  <Router>
    <div>
      <Switch>
        <Route exact path="/" component={App} />
        <Route path="/about" component={About} />
        <Route path="/get-started" component={GetStarted} />
        <Route path="/articles" component={Articles} />
        <Route path="/inspiration" component={Inspiration} />
        <Route path="/design" component={Design} />
      </Switch>
    </div>
  </Router>
);

ReactDOM.render(routing, document.getElementById("root"));

