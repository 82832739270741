import firebase from "firebase/app";
import 'firebase/database';

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
// var firebase = require("firebase/app");

// Add the Firebase products that you want to use
// require("firebase/auth");
// require("firebase/firestore");

// DO NOT COMMIT TO GITHUB B/C API KEY!!
// Your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDJCOXo804GYpwWd1mI8yM2mnncpq9V9NU",
  authDomain: "greener-thumb.firebaseapp.com",
  databaseURL: "https://greener-thumb.firebaseio.com",
  projectId: "greener-thumb",
  storageBucket: "greener-thumb.appspot.com",
  messagingSenderId: "168729097987",
  appId: "1:168729097987:web:6d98dce5c13ee0a7209b36",
  measurementId: "G-BD113877T5"
};

const app = firebase.initializeApp(firebaseConfig);

export const db = app.database();

// // Initialize Firebase
// firebase.initializeApp(firebaseConfig);
// const zipCode=97335;
// // var database = firebase.database();
// function query(phm_us_zipcode){
//   return firebase.database().ref('/phm_us_zipcode/' + zipCode).once('value').then(function(snapshot) {
//     var hardiness = (snapshot.val() && snapshot.val().zone) ;
//     console.log(hardiness);
//   });
// }
// alert(query(zipCode));
