import React from "react";
import Footer from "./Footer";
import ExampleNavbar from "./Navbar";
import { Button, Container, Jumbotron, Col, Row, Media} from 'reactstrap';

const imgUrl = "https://images.unsplash.com/photo-1518531933037-91b2f5f229cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1800&q=80"
const backgroundImgStyle = {
  backgroundImage: 'url('+imgUrl+')',
  color: 'white',
}

class App extends React.Component{

  render(){
    return (
      <div >
        <ExampleNavbar />
        <Jumbotron fluid style={backgroundImgStyle}>
          <Container fluid>
            <p className="lead"> Welcome to the <strong>Greener Thumb</strong>.</p>
            <h1 className="display-3" ><i>Refined</i> gardening </h1>
            <p className="lead"> for beginners.</p>
          </Container>
        </Jumbotron>
        {/* className="themed-container"  */}
        {/* <Container  > */}

        <Row>
            <Media>

              <Col>

              <Media body>
                {/* <Media heading>Purpose</Media> */}
                <h3 className="display-4">Purpose</h3>
                <p className="lead">The Greener Thumb consolidates tools, tips, and resources to help aspiring gardeners. </p>
                <Media >
  
                  <Media body>
                    <p className="lead">- Find plants that thrive in your climate.</p>
                    <p className="lead">- Design and cultivate your garden retreat.</p>
                    <p className="lead">- Nurture and maintain the garden of your dreams.</p>
                  </Media>
                </Media>
                {/* <Media right href="https://images.unsplash.com/photo-1438109382753-8368e7e1e7cf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80">
                  <Media object className="style-img" src="https://images.unsplash.com/photo-1438109382753-8368e7e1e7cf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80" alt="purpose image" />
                  <p className="caption">Photo by Benjamin Combs from Unsplash.</p>

                </Media> */}
                <Button size="lg" href="/About" path="/about">Read More</Button>

              </Media>
              </Col>
              <Col>
              {/* <img className="style-img" src="https://images.unsplash.com/photo-1472377622610-57ef965c89f1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80" alt="central gardening by Eduard Militaru" href="https://unsplash.com/photos/VXiLCpne3cI"/> */}
              <h3 className="display-4">Design </h3>
              <p className="lead">Browse ideas and design your retreat to fit your style and space.</p>
              <Media>
                <Media body>
                  <p className="lead">- <i>Space: </i>Indoor, outdoor, balcony, deck, patio, window sill ... </p>
                  <p className="lead">- <i>Plants:</i> Flowers, herbs, vegetables, succulents...</p>
                  <p className="lead">- <i>Style: </i>Minimal, modern, elegant, traditional, ... </p>
                  <p className="lead">- <i>Mood: </i>Elegant, simple, relaxed, playful, adventurous... </p>
                </Media>
              </Media>
              <Button size="lg" href="/Design" path="/design" >Learn More</Button>

              </Col>

            <Col>
            <h3 className="display-4">Learn</h3>

              {/* <h4 >Plant Care</h4> */}
              <p className="lead">The health of your plants depends on their environment. Find the best plants for your climate, needs, and exposure to sunlight. Learn your USDA Hardiness Zone and find plants based on your USDA Hardiness zone.</p>


              {/* <h4 >USDA Hardiness Zone</h4> */}

              {/* <p className="lead">Find plants that thrive in your climate. </p> */}
              <Button size="lg"  href="/get-started" path="/get-started" >Get Started</Button>
            </Col>

            </Media>

          </Row>
        {/* </Container> */}

    
        <Footer />
      </div>
    );
  };
};
export default App;

// {/* <h2>Location</h2>
          
// <p>Where will your plants grow?</p>
// <Container>

//   <Row>
//     <Col md="6">
//      <h3>Indoors</h3>
//      <p className="lead" >Tropical plants tend do well indoors, due to minimal temperature fluctuations.</p>
//      <p>To avoid pests, consider potting mix for indoor plants.</p>
//      <Button size="lg" variant="secondary" href= "https://www.goodhousekeeping.com/home/gardening/advice/g1285/hard-to-kill-plants/">Browse</Button>
//     </Col>
//     <Col md="6">
//       <h3>Outdoors</h3>
//       <p className="lead" >Find plants that thrive in your climate by determining your <a href="https://planthardiness.ars.usda.gov/PHZMWeb/">USDA Hardiness Zone</a>.</p>
//       <Input type="text" name="zip" id="zipCode" value={zip} placeholder="ZIP code"> </Input>
//       <Button onClick={handleClick} value={zip} color="secondary" size="lg" ><SearchIcon /></Button>
//     </Col>
//   </Row>            


// </Container>

// <h2>sunlight</h2>
// <p className="lead">How much sun exposure the plants will recieve?</p>
// <ButtonGroup aria-label="Basic example">
//   <Button variant="secondary"><WbSunnyIcon /></Button>
//   <Button variant="secondary"><WbCloudyIcon /></Button>
//   <Button variant="secondary"><CloudQueueIcon /></Button>
// </ButtonGroup>

// <SearchUSDA zip={zip} /> */}